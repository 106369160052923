import {ApplicationContactId, ApplicationConversationId} from '@frontapp/plugin-sdk'
import {SelectKey} from 'lib/enums'
import {note as noteConsts, request as requestConsts} from 'queries/constants'
import {IResponse} from 'types/app'

export type ErpRequestSource = keyof typeof requestConsts.source

export type ErpRequestWording = keyof typeof requestConsts.wording

export type NoteStatus = keyof typeof noteConsts.status

export interface ILoginPayload {
  email: string
  password: string
}

export interface ILookup {
  q?: any
  limit?: number
  sort?: any
  hydrate?: boolean
  matchLookup?: any
}

export interface ILoginResponse {
  profile: any
  jwt: string
}

export interface IErpRequestContact {
  id?: ApplicationContactId
  firstname?: string
  lastname?: string
  phone?: string
  email?: string
}

export interface IErpRequestDefunct {
  firstname?: string
  lastname?: string
  dod?: Date
  ageRange?: string
  gender?: string
}

export interface IErpRequestAttendant {
  _id: string
  firstname: string
  lastname: string
  name: string
}

export type ErpRequestStep = 'new' | 'mql' | 'sql' | 'order' | 'abandoned' | 'cpo'

export interface IErpRequest {
  error?: any
  loading?: boolean | undefined
  utms?: {
    term: any
    content: string
    medium: string
    source: string
  }
  _id?: string // request mongoId
  abandonmentReason?: string
  appointmentTaken?: boolean
  contact?: IErpRequestContact
  defunct?: IErpRequestDefunct
  attendant?: string | IErpRequestAttendant
  ceremonyType?: string
  contactReached?: string
  conversationId?: ApplicationConversationId
  counter?: number
  customAbandonmentReason?: string
  frontContactId?: ApplicationContactId | undefined
  house?: string | any // legacy: house is now called Region but we didn't changed our DB schema yet
  agency?: string | any // In each Region we have Agencies
  informationType?: string
  isAbandoned?: boolean
  note?: any
  notes?: Array<any>
  numberCalled?: string
  numberCalling?: string
  partnershipContact?: IErpRequestContact
  pending?: boolean
  projects?: Array<any>
  recommandationOrigin?: string
  ref?: string
  requestType?: string
  situationDetails?: string
  source?: ErpRequestSource
  step?: ErpRequestStep
  type?: string
  wording?: ErpRequestWording
  isFirstStepManual?: boolean
  appointmentDate?: Date
  appointmentAtHome?: boolean
  situationInfo?: string
  relationship?: string
}

export interface IErpNote {
  _id?: string
  status: NoteStatus
  data: string
  type: string
}

export interface IAbandonmentPayload {
  requestId?: string
  abandonmentReason?: string
}

export interface ILookupResponse<TData = any> {
  data: {
    $match: any
    data: Array<TData>
    pager: any
    search: string
  }
}

export interface IUpdateProjectPayload {
  _id?: string
  status?: string
}

export interface IOption {
  key: string
  value: string
}

export interface ISelectResponse {
  _id: string
  key: SelectKey
  options: Array<IOption>
}

export interface ApplicationCommentConversation {
  conversationId: string
  comment: {body: string}
}

export interface IShallowRequest extends IErpRequest {
  status?: string
}

export type ApiResponse<T = any> = Promise<IResponse<T>>
export type RequestField = keyof IErpRequest

export interface IRingoverNumber {
  number: number
  label: string
  type: 'PHONE'
  user_id: number
  ivr_id: number
  conference_id: number
  is_sms: boolean
  is_sms_write: boolean
  is_callable: boolean
  format: any
}

export interface IRingoverNumbersResponse {
  list_count: number
  list: IRingoverNumber[]
}

export interface IUserProfile {
  _id: string
  name: string
  email: string
}

// select: 6634ae828721c991f6ebce05
export enum TypePrecision {
  ErrorOrOffTopic = 'errorOrOffTopic',
  TributePage = 'tributePage',
  Journalist = 'journalist',
  Candidate = 'candidate',
  Canvassing = 'canvassing',
  OtherFH = 'otherFH',
  FamilyManyContacts = 'familyManyContacts',
  NeverHadByPhone = 'neverHadByPhone',
  OtherTeam = 'otherTeam'
}
